import {
    FolderIcon,
    HomeIcon,
    LogoutIcon,
    TrashIcon
} from '@heroicons/react/solid';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import classNames from '../../utilities/utilities';

const navigation = [
    {
        name: 'Dateien',
        href: '/',
        icon: FolderIcon,
        current: true
    },
    {
        name: 'Objekte',
        href: '/properties',
        icon: HomeIcon,
        current: false
    },
    {
        name: 'Müll',
        href: '/trash',
        icon: TrashIcon,
        current: false
    }
];

export default function SideBar() {
    const history = useHistory();
    const [active, setActive] = useState(history.location.pathname);
    const { authState, oktaAuth } = useOktaAuth();
    const logout = async () => {
        // Will redirect to Okta to end the session then redirect back to the configured `postLogoutRedirectUri`
        await oktaAuth.signOut();
    };

    useEffect(
        () =>
            history.listen((location) => {
                setActive(location.pathname);
            }),
        [, history]
    );

    const [sidebarOpen, setSidebarOpen] = useState(false);
    return (
        <div className="hidden md:flex md:flex-shrink-0">
            <div className="flex flex-col w-64">
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex flex-col h-0 flex-1 border-r border-gray-200 bg-gray-100">
                    <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
                        <div className="flex items-center flex-shrink-0 px-4">
                            <img
                                className="h-8 w-auto"
                                src="https://tailwindui.com/img/logos/workflow-logo-pink-500-mark-gray-900-text.svg"
                                alt="Workflow"
                            />
                        </div>
                        <nav className="mt-5 flex-1" aria-label="Sidebar">
                            <div className="px-2 space-y-1">
                                {navigation.map((item) => (
                                    <span
                                        key={item.name}
                                        onClick={() => history.push(item.href)}
                                        className={classNames(
                                            active === item.href ||
                                                (item.href === '/' &&
                                                    active !== '/properties' &&
                                                    active !== '/trash')
                                                ? 'bg-gray-200 text-gray-900'
                                                : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                                            'group flex items-center px-2 py-2 text-sm font-medium rounded-md cursor-pointer'
                                        )}
                                        aria-current={
                                            item.current ? 'page' : undefined
                                        }
                                    >
                                        <item.icon
                                            className={classNames(
                                                active === item.href ||
                                                    (item.href === '/' &&
                                                        active !==
                                                            '/properties' &&
                                                        active !== '/trash')
                                                    ? 'text-gray-500'
                                                    : 'text-gray-400 group-hover:text-gray-500',
                                                'mr-3 flex-shrink-0 h-6 w-6'
                                            )}
                                            aria-hidden="true"
                                        />
                                        {item.name}
                                    </span>
                                ))}
                                <span
                                    key="logout"
                                    onClick={logout}
                                    className="mt-auto text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md cursor-pointer"
                                >
                                    <LogoutIcon
                                        className={classNames(
                                            'text-gray-400 group-hover:text-gray-500',
                                            'mr-3 flex-shrink-0 h-6 w-6'
                                        )}
                                        aria-hidden="true"
                                    />
                                    Ausloggen
                                </span>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    );
}
