import axios from 'axios';
import { API_URL } from '../utilities/api';

/**
 * File containing all the type helpers for files
 */

export type file = {
    _id: string;
    filename: string;
    length: number;
    chunkSize: number;
    uploadDate: string;
    md5: string;
    contentType: string;
    metadata: {
        parent: string;
    };
    notes?: string;
    tag?: string;
};

// @ts-ignore
export function uploadFile(
    folderId,
    file,
    property = '0',
    fileToReplace = null
) {
    console.log('Filetoreplace' + fileToReplace);
    return new Promise((resolve, reject) => {
        const formData = new FormData();
        // @ts-ignore
        formData.append('parent', folderId || '0');
        // @ts-ignore
        formData.append('property', property);
        // @ts-ignore
        formData.append('file', file);

        let url = fileToReplace
            ? `${API_URL}/file/${fileToReplace}`
            : `${API_URL}/file`;

        axios
            .post(url, formData, {
                onUploadProgress: (progressEvent: { loaded: any }) => {
                    // console.log(progressEvent.loaded)
                }
            })
            .then((response: any) => {
                console.log('-------------');
                console.log(response);
                console.log('-------------');
                resolve(response.data);
                // @ts-ignore
                // setFiles([...files, response.data]);
                // eslint-disable-next-line no-unused-expressions
                // this.fetchRecent();
            })
            .catch((err: any) => {
                reject('fail');
            });
    });
}

// @ts-ignore
export async function uploadFiles(folderId, files, property = '0') {
    /*
    const { oktaAuth } = useOktaAuth();
    console.log({
        headers: {
            'content-type': 'application/json',
            accept: 'application/json',
            authorization: `Bearer ${await oktaAuth.getAccessToken()}`
        }
    });
    */
    return new Promise((resolve, reject) => {
        console.log(files);
        const x = [];
        files.map((f) => x.push(uploadFile(folderId, f)));
        Promise.all(x)
            .then((r) => {
                console.log(r);
                resolve(r);
            })
            .catch((e) => {
                alert('Upload failed, please reload the page');
                reject('flop');
            });
    });
}

// @ts-ignore
export function updateFileContents(folderId, fileId, file) {
    return new Promise(async (resolve, reject) => {
        await deleteFile(fileId);
        const formData = new FormData();
        // @ts-ignore
        formData.append('parent', folderId || '0');
        // @ts-ignore
        formData.append('file', file);

        axios
            .post('https://h2950720.stratoserver.net/api/file', formData, {
                onUploadProgress: (progressEvent: { loaded: any }) => {
                    // console.log(progressEvent.loaded)
                }
            })
            .then((response: any) => {
                console.log('-------------');
                console.log(response);
                console.log('-------------');
                resolve(response.data);
                // @ts-ignore
                // setFiles([...files, response.data]);
                // eslint-disable-next-line no-unused-expressions
                // this.fetchRecent();
            })
            .catch((err: any) => {
                reject('fail');
            });
    });
}

export async function deleteFile(id: string) {
    try {
        const resp = await axios.delete(`${API_URL}/file_delete/${id}`);
        return id;
    } catch (err) {
        // Handle Error Here
        return null;
    }
}

export async function revertFile(id: string) {
    try {
        const resp = await axios.post(`${API_URL}/revert_file/${id}`);
        return id;
    } catch (err) {
        // Handle Error Here
        return null;
    }
}

export async function editFile(data: file) {
    try {
        const resp = await axios.put(`${API_URL}/file/`, {
            ...data
        });
        return resp.data;
    } catch (err) {
        // Handle Error Here
        return null;
    }
}
