const OKTA_TESTING_DISABLEHTTPSCHECK =
    process.env.OKTA_TESTING_DISABLEHTTPSCHECK || false;

export default {
    oidc: {
        clientId: '0oa1aub4tghRCiI925d7',
        issuer: 'https://dev-46421350.okta.com/oauth2/default',
        redirectUri: 'https://h2950720.stratoserver.net/login/callback',
        scopes: ['openid', 'profile', 'email'],
        pkce: true,
        disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK
    },
    resourceServer: {
        messagesUrl: 'https://h2950720.stratoserver.net/api/messages'
    }
};
