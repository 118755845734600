import axios from 'axios';
import { API_URL } from '../utilities/api';

export async function getProperties() {
    try {
        const resp = await axios.get(`${API_URL}/properties`);
        return resp.data;
    } catch (err) {
        // Handle Error Here
        return [];
    }
}

export async function addProperty(name = '') {
    try {
        const resp = await axios.post(`${API_URL}/property`, {
            name
        });
        return resp.data;
    } catch (err) {
        // Handle Error Here
        return null;
    }
}

export async function deleteProperty(id) {
    try {
        const resp = await axios.delete(`${API_URL}/property/${id}`);
        return resp.data;
    } catch (err) {
        // Handle Error Here
        return null;
    }
}
