// @ts-ignore
export default function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

export function niceBytes(x: string) {
    let l = 0;
    let n = parseInt(x, 10) || 0;

    while (n >= 1024 && ++l) {
        n /= 1024;
    }

    return `${n.toFixed(n < 10 && l > 0 ? 1 : 0)} ${units[l]}`;
}

export function isFileNameInDirectory(name: string, files: Array<file>) {
    return files.find((f) => f.filename === name);
}

export function getFileName(file: any, files: any) {
    if (isFileNameInDirectory(file.name, [...files])) {
        console.log(`Filename ${file.name} is present`);
        let cnt = 1;
        while (
            isFileNameInDirectory(
                `${file.name.substring(
                    0,
                    file.name.lastIndexOf('.')
                )}-${cnt}${file.name.substring(file.name.lastIndexOf('.'))}`,
                [...files]
            )
        ) {
            cnt++;
        }
        console.log(
            `${file.name.substring(
                0,
                file.name.lastIndexOf('.')
            )}-${cnt}${file.name.substring(file.name.lastIndexOf('.'))}`
        );

        return `${file.name.substring(
            0,
            file.name.lastIndexOf('.')
        )}-${cnt}${file.name.substring(file.name.lastIndexOf('.'))}`;
    }
    return file.name;
}
