const { useState, useEffect } = require('react');
const { getProperties } = require('../types/properties');

export const useProperties = () => {
    const [properties, setProperties] = useState([]);

    useEffect(() => {
        (async function () {
            const p = await getProperties();
            setProperties(p);
        })();
    }, []);

    return { properties };
};
