import React, { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import {
    DownloadIcon as DownloadIconFilled,
    ExternalLinkIcon,
    LinkIcon,
    PrinterIcon,
    TrashIcon,
    UploadIcon
} from '@heroicons/react/solid';
import { useDropzone } from 'react-dropzone';
import { getFileDetails } from '../../utilities/files';
import classNames, { niceBytes } from '../../utilities/utilities';
import {
    deleteFile,
    editFile,
    uploadFile,
    uploadFiles
} from '../../types/files';

export default function FilePanel({
    _id,
    setOpen,
    deleteCallback,
    refresh,
    properties = []
}) {
    const [file, setFile] = useState({});
    const [loading, setLoading] = useState(false);

    const onDrop = async function (acceptedFiles) {
        if (confirm('Do you want to overwrite it?')) {
            setLoading(true);
            uploadFile(
                file.metadata.parent,
                acceptedFiles[0],
                file?.metadata?.property || '0',
                _id
            )
                .then(async (f) => {
                    //await deleteFile(_id, f._id);
                    refresh(f);
                })
                .catch((e) => {
                    alert('Fehler beim Hochladen der Datei');
                })
                .finally((e) => {
                    setLoading(false);
                });
        }
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        multiple: false
    });

    function printPdf(url) {
        const iframe = document.createElement('iframe');
        // iframe.id = 'pdfIframe'
        iframe.className = 'pdfIframe';
        document.body.appendChild(iframe);
        iframe.style.display = 'none';
        iframe.onload = function () {
            setTimeout(() => {
                iframe.focus();
                iframe.contentWindow.print();
                URL.revokeObjectURL(url);
                // document.body.removeChild(iframe)
            }, 1);
        };
        iframe.src = url;
        // URL.revokeObjectURL(url)
    }

    useEffect(() => {
        setLoading(false);
        if (_id) {
            (async () => {
                try {
                    const f = await getFileDetails(_id);
                    setFile(f);
                    console.log(f);
                } catch {
                    console.log('ERROR');
                }
            })();
        }
    }, [_id]);

    return (
        <Transition.Root show={!!_id} as={Fragment}>
            <Dialog
                as="div"
                static
                className="fixed inset-0 overflow-hidden"
                open={!!_id}
                onClose={setOpen}
            >
                <div {...getRootProps()}>
                    <div className="absolute inset-0 overflow-hidden">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-in-out duration-500"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in-out duration-500"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>
                        <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <div className="relative w-screen max-w-md">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-in-out duration-500"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="ease-in-out duration-500"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <div className="absolute top-0 left-0 -ml-8 pt-4 pr-2 flex sm:-ml-10 sm:pr-4">
                                            <button
                                                type={'reset'}
                                                className="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                                onClick={() => setOpen(false)}
                                            >
                                                <span className="sr-only">
                                                    Schließen
                                                </span>
                                                <XIcon
                                                    className="h-6 w-6"
                                                    aria-hidden="true"
                                                />
                                            </button>
                                        </div>
                                    </Transition.Child>
                                    <div
                                        className={classNames(
                                            'h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll',
                                            isDragActive && 'border-indigo-600'
                                        )}
                                    >
                                        {!loading ? (
                                            <>
                                                <div className="px-4 sm:px-6">
                                                    <Dialog.Title className="text-lg font-medium text-gray-900">
                                                        {file.filename}
                                                    </Dialog.Title>
                                                </div>

                                                <div className="mt-6 relative flex-1 px-4 sm:px-6 ">
                                                    {/*
                                             <a
                                                href={`http://localhost:5001/file/${file._id}`}
                                                type="button"
                                                target={"_blank"}
                                                className="inline-flex items-center mr-2 p-2 border border-transparent rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            >
                                                <ExternalLinkIcon className="h-5 w-5" aria-hidden="true" />
                                            </a>
                                            */}
                                                    <a
                                                        href={`https://h2950720.stratoserver.net/api/file/${file._id}`}
                                                        type="button"
                                                        download={file.filename}
                                                        className="inline-flex items-center mr-2 p-2 border border-transparent rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                    >
                                                        <DownloadIconFilled
                                                            className="h-5 w-5"
                                                            aria-hidden="true"
                                                        />
                                                    </a>
                                                    {/*
                                            <span
                                                className="disabled cursor-pointer inline-flex items-center mr-2 my-2 p-2 border border-transparent rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                onClick={() => {

                                                    printPdf(
                                                        `https://h2950720.stratoserver.net/api/file/${file._id}`
                                                    );
                                                }}
                                            >
                                                <PrinterIcon
                                                    className="h-5 w-5"
                                                    aria-hidden="true"
                                                />
                                            </span>
                                            */}
                                                    <label className="inline-flex items-center mr-2 p-2 border border-transparent rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                                        <input
                                                            type="file"
                                                            className="hidden"
                                                            multiple={false}
                                                            onChange={async (
                                                                e: any
                                                            ) => {
                                                                await onDrop(
                                                                    Array.from(
                                                                        e.target
                                                                            .files
                                                                    )
                                                                );
                                                            }}
                                                        />
                                                        <UploadIcon
                                                            className="h-5 w-5"
                                                            aria-hidden="true"
                                                        />
                                                    </label>
                                                    <span
                                                        className="cursor-pointer inline-flex items-center my-2 p-2 border border-transparent rounded-full shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                                        onClick={async () => {
                                                            const x =
                                                                await deleteFile(
                                                                    file._id
                                                                );
                                                            if (x) {
                                                                deleteCallback(
                                                                    file._id
                                                                );
                                                            } else {
                                                                alert(
                                                                    `Error deleting file, ${file._id}`
                                                                );
                                                            }
                                                        }}
                                                    >
                                                        <TrashIcon
                                                            className="h-5 w-5"
                                                            aria-hidden="true"
                                                        />
                                                    </span>
                                                    <div className="border-t border-gray-200 px-4 py-5 sm:p-0 mt-4">
                                                        <dl className="sm:divide-y sm:divide-gray-200">
                                                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                                                <dt className="text-sm font-medium text-gray-500">
                                                                    Dateiname
                                                                </dt>
                                                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                                    {
                                                                        file.filename
                                                                    }
                                                                </dd>
                                                            </div>
                                                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                                                <dt className="text-sm font-medium text-gray-500">
                                                                    Objekt
                                                                </dt>
                                                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                                    <div>
                                                                        <select
                                                                            id="location"
                                                                            name="location"
                                                                            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                                                            value={
                                                                                file.hasOwnProperty(
                                                                                    'metadata'
                                                                                ) &&
                                                                                file.metadata.hasOwnProperty(
                                                                                    'property'
                                                                                )
                                                                                    ? file
                                                                                          .metadata
                                                                                          .property
                                                                                    : '0'
                                                                            }
                                                                            onChange={async (
                                                                                event
                                                                            ) => {
                                                                                const f =
                                                                                    {
                                                                                        ...file
                                                                                    };
                                                                                f.metadata.property =
                                                                                    event.target.value;
                                                                                const x =
                                                                                    await editFile(
                                                                                        f
                                                                                    );
                                                                                refresh(
                                                                                    x
                                                                                );
                                                                                // console.log(event.target.value)
                                                                            }}
                                                                        >
                                                                            <option value="0">
                                                                                Kein
                                                                                Objekt
                                                                            </option>
                                                                            {properties.map(
                                                                                (
                                                                                    p
                                                                                ) => (
                                                                                    <option
                                                                                        value={
                                                                                            p._id
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            p.name
                                                                                        }
                                                                                    </option>
                                                                                )
                                                                            )}
                                                                        </select>
                                                                    </div>
                                                                </dd>
                                                            </div>

                                                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                                                <dt className="text-sm font-medium text-gray-500">
                                                                    Dateityp
                                                                </dt>
                                                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                                    {
                                                                        file.contentType
                                                                    }
                                                                </dd>
                                                            </div>
                                                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                                                <dt className="text-sm font-medium text-gray-500">
                                                                    Letzte
                                                                    Änderung
                                                                </dt>
                                                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                                    {new Date(
                                                                        file.uploadDate
                                                                    ).toLocaleDateString()}
                                                                </dd>
                                                            </div>
                                                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                                                <dt className="text-sm font-medium text-gray-500">
                                                                    Grösse
                                                                </dt>
                                                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                                    {niceBytes(
                                                                        file.length
                                                                    )}
                                                                </dd>
                                                            </div>
                                                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                                                <dt className="text-sm font-medium text-gray-500">
                                                                    _id
                                                                </dt>
                                                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                                    {file._id}
                                                                </dd>
                                                            </div>
                                                        </dl>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <div className="px-4 sm:px-6">
                                                <Dialog.Title className="text-lg font-medium text-gray-900">
                                                    Bitte warten
                                                </Dialog.Title>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
