import SideBar from './components/SideBar/SideBar';
import React from 'react';

export default function PageLayout({ children }) {
    return (
        <>
            <SideBar />
            <div className="flex flex-col w-100 flex-1 overflow-hidden">
                <main className="flex-1 relative overflow-y-auto focus:outline-none">
                    <div className="py-6">
                        <div className="max-w-8xl mx-auto px-2 sm:px-4 md:px-6">
                            <div className="py-4 h-full">{children}</div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
}
