import {
    DocumentAddIcon,
    FolderAddIcon,
    SearchIcon,
    TrashIcon
} from '@heroicons/react/solid';
import { getFileName } from '../../utilities/utilities';
import { uploadFiles } from '../../types/files';
import { emptyTrash } from '../../types/folders';
import React from 'react';

function FileBrowserHeader({
    setShowFolderModal,
    folderName,
    folderId,
    files,
    setFiles,
    setFolders,
    search,
    setSearch
}) {
    return (
        <div className="pb-5 sm:flex sm:items-center">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
                {folderName}
            </h3>
            <div className="mt-3 sm:mt-0 ml-auto">
                <label htmlFor="search-candidate" className="sr-only">
                    Suche
                </label>
                <div className="flex rounded-md shadow-sm">
                    <div className="relative flex-grow focus-within:z-10">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <SearchIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                            />
                        </div>
                        <input
                            type="text"
                            name="search-candidate"
                            id="search-candidate"
                            className="focus:ring-indigo-500 focus:border-indigo-500 w-full rounded-md pl-10 sm:block sm:text-sm border-gray-300"
                            placeholder="Suche"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </div>
                </div>
            </div>
            {folderId !== 'trash' ? (
                <>
                    <button
                        type="button"
                        onClick={() => setShowFolderModal(true)}
                        className="ml-2 inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        <FolderAddIcon
                            className="-ml-0.5 mr-2 h-4 w-4"
                            aria-hidden="true"
                        />
                        Neue Ordner
                    </button>
                    <label className="cursor-pointer ml-2 inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        <input
                            type="file"
                            className="hidden"
                            multiple
                            onChange={(event) => {
                                let parsedFiles = [];
                                for (const f of Array.from(
                                    event.target.files
                                )) {
                                    parsedFiles.push(
                                        new File([f], getFileName(f, files), {
                                            type: f.type
                                        })
                                    );
                                }
                                uploadFiles(folderId, parsedFiles)
                                    .then((f) => {
                                        setFiles([...files, ...f]);
                                    })
                                    .catch((e) => {
                                        console.log(e);
                                        alert('Error uploading files');
                                    });
                            }}
                        />
                        <DocumentAddIcon
                            className="-ml-0.5 mr-2 h-4 w-4"
                            aria-hidden="true"
                        />
                        Datei hoch laden
                    </label>
                </>
            ) : (
                <button
                    type="button"
                    onClick={async () => {
                        if (confirm('Wollen Sie den Papierkorb leeren?')) {
                            await emptyTrash();
                            setFiles([]);
                            setFolders([]);
                        }
                    }}
                    className="ml-2 inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                    <TrashIcon
                        className="-ml-0.5 mr-2 h-4 w-4"
                        aria-hidden="true"
                    />
                    Papierkorb leeren
                </button>
            )}
        </div>
    );
}

export default FileBrowserHeader;
