import axios, { get, post } from 'axios';

const API_URL = 'https://h2950720.stratoserver.net/api';

export async function getFileDetails(_id) {
    try {
        const resp = await get(`${API_URL}/file-details/${_id}`);
        return resp.data;
    } catch (err) {
        // Handle Error Here
        return [];
    }
}
