import axios from 'axios';
import { API_URL } from '../utilities/api';

/**
 * File containing all the type helpers for folders
 */

export type folder = {
    _id: string;
    name: string;
    parent: string;
    notes?: string;
};

export async function getItems(parent = '0') {
    try {
        const resp = await axios.get(`${API_URL}/folder/${parent}`);
        return resp.data;
    } catch (err) {
        // Handle Error Here
        return [];
    }
}

export async function addFolder(parent = '0', name = '') {
    try {
        const resp = await axios.post(`${API_URL}/folder`, {
            parent: String(parent),
            name
        });
        return resp.data;
    } catch (err) {
        // Handle Error Here
        return null;
    }
}

export async function deleteFolder(id: string) {
    try {
        const resp = await axios.delete(`${API_URL}/folder/${id}`);
        return resp.data;
    } catch (err) {
        throw 'The folder is not empty';
        // return null;
    }
}

export async function editFolder(data: folder) {
    try {
        const resp = await axios.put(`${API_URL}/folder/`, {
            ...data
        });
        return resp.data;
    } catch (err) {
        // Handle Error Here
        return null;
    }
}

export async function emptyTrash() {
    try {
        const resp = await axios.post(`${API_URL}/empty_trash/`);
        return resp;
    } catch (err) {
        console.log(err);
        // Handle Error Here
        return null;
    }
}
