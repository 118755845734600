import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import {
    DocumentIcon,
    DotsVerticalIcon,
    FolderIcon,
    PencilAltIcon,
    ReplyIcon,
    TrashIcon
} from '@heroicons/react/solid';
import { useDropzone } from 'react-dropzone';
import classNames, {
    getFileName,
    isFileNameInDirectory,
    niceBytes
} from '../../../utilities/utilities';
import DropDown from '../../../components/DropDown/DropDown';
import FilePanel from '../../../components/FilePanel/FilePanel';
import { deleteFolder } from '../../../types/folders';
import EditFolderModal from '../../../components/EditFolderModal/EditFolderModal';
import {
    deleteFile,
    revertFile,
    uploadFile,
    uploadFiles
} from '../../../types/files';
import ConfirmModal from '../../../components/ConfirmModal/ConfirmModal';
import DuplicateModal from '../../../components/DuplicateModal/DuplicateModal';
import EditFileModal from '../../../components/EditFileModal/EditFileModal';

export default function FilesTable({
    folders,
    setFolders,
    folder,
    files,
    setFiles,
    properties,
    ...props
}: any) {
    const history = useHistory();
    const [selectedFileId, setSelectedFileId] = useState(null);
    const [showEditFolder, setShowEditFolder] = useState(false);
    const [showReplaceFileModal, setShowReplaceFileModal] = useState(false);

    const onDrop = async function (acceptedFiles: any) {
        let parsedFiles = [];
        for (const f of acceptedFiles) {
            parsedFiles.push(
                new File([f], getFileName(f, files), { type: f.type })
            );
        }
        uploadFiles(folder, parsedFiles)
            .then((f) => {
                setFiles([...files, ...f]);
            })
            .catch((e) => {
                console.log(e);
                alert('Error uploading file');
            });
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        multiple: true
    });
    const [showEditFile, setShowEditFile] = useState(false);

    const isTrash = folder === 'trash';
    // @ts-ignore
    return (
        <>
            <div {...getRootProps()}>
                <div
                    className={classNames(
                        'transition duration-200 ease-in-out rounded-lg border-2 border-gray-200 align-middle inline-block min-w-full border-gray-200',
                        isDragActive && 'border-indigo-600'
                    )}
                >
                    <table className="min-w-full">
                        <thead className="sticky top-0">
                            <tr className="border-t border-gray-200">
                                <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    <span className="lg:pl-2">Dateiname</span>
                                </th>
                                <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Objekt
                                </th>
                                <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Größe
                                </th>
                                <th className="hidden md:table-cell px-6 py-3 border-b border-gray-200 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                                    Letzte Änderung
                                </th>
                                <th className="pr-6 py-3 border-b border-gray-200 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider" />
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-100">
                            {folders?.map((f: any) => (
                                <tr
                                    key={f._id}
                                    onClick={() =>
                                        history.push({ pathname: f._id })
                                    }
                                >
                                    <td className="px-6 py-3 max-w-0 w-full whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer">
                                        <div className="flex items-center space-x-2 fill lg:pl-2">
                                            <FolderIcon className="w-5 h-5 text-gray-400" />

                                            <span className="truncate hover:text-gray-600">
                                                <span>{f.name}</span>
                                            </span>
                                        </div>
                                    </td>
                                    <td className="px-6 py-3 text-sm text-gray-500">
                                        <div className="flex items-center space-x-2">
                                            <div className="flex flex-shrink-0 -space-x-1">
                                                -
                                            </div>
                                        </div>
                                    </td>
                                    <td className="px-6 py-3 text-sm text-gray-500">
                                        <div className="flex items-center space-x-2">
                                            <div className="flex flex-shrink-0 -space-x-1">
                                                -
                                            </div>
                                        </div>
                                    </td>
                                    <td className="hidden md:table-cell px-6 py-3 whitespace-nowrap text-sm text-gray-500 text-right">
                                        -
                                    </td>
                                    <td className="pr-6">
                                        <DropDown>
                                            <div className="py-1">
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <span
                                                            className={classNames(
                                                                active
                                                                    ? 'bg-gray-100 text-gray-900'
                                                                    : 'text-gray-700',
                                                                'group flex items-center px-4 py-2 text-sm'
                                                            )}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                setShowEditFolder(
                                                                    f
                                                                );
                                                            }}
                                                        >
                                                            <PencilAltIcon
                                                                className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                                                aria-hidden="true"
                                                            />
                                                            Bearbeiten
                                                        </span>
                                                    )}
                                                </Menu.Item>
                                            </div>
                                            <div className="py-1">
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <span
                                                            onClick={async (
                                                                e
                                                            ) => {
                                                                if (
                                                                    confirm(
                                                                        'Sind Sie sicher, dass Sie diesen Ordner löschen wollen?'
                                                                    )
                                                                ) {
                                                                    try {
                                                                        e.stopPropagation();
                                                                        await deleteFolder(
                                                                            f._id
                                                                        );
                                                                        setFolders(
                                                                            [
                                                                                ...folders
                                                                            ].filter(
                                                                                (
                                                                                    fld
                                                                                ) =>
                                                                                    fld._id !==
                                                                                    f._id
                                                                            )
                                                                        );
                                                                    } catch {
                                                                        alert(
                                                                            'Der Ordner muss leer sein, damit er gelöscht werden kann.'
                                                                        );
                                                                    }
                                                                }
                                                            }}
                                                            className={classNames(
                                                                active
                                                                    ? 'bg-gray-100 text-gray-900'
                                                                    : 'text-gray-700',
                                                                'group flex items-center px-4 py-2 text-sm cursor-pointer'
                                                            )}
                                                        >
                                                            <TrashIcon
                                                                className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                                                aria-hidden="true"
                                                            />
                                                            Löschen
                                                        </span>
                                                    )}
                                                </Menu.Item>
                                            </div>
                                        </DropDown>
                                    </td>
                                </tr>
                            ))}
                            {files
                                ?.sort((a: any, b: any) =>
                                    a.filename.localeCompare(b.filename)
                                )
                                .map((item: any) => (
                                    <tr
                                        key={item._id}
                                        onClick={() => {
                                            console.log(item._id);
                                            setSelectedFileId(item._id);
                                        }}
                                    >
                                        <td className="px-6 py-3 max-w-0 w-full whitespace-nowrap text-sm font-medium text-gray-900">
                                            <div className="flex items-center space-x-2 fill lg:pl-2">
                                                <DocumentIcon className="w-5 h-5 text-gray-400" />
                                                <span className="truncate hover:text-gray-600 cursor-pointer">
                                                    {item.filename}
                                                </span>
                                            </div>
                                        </td>
                                        <td className="px-6 py-3 text-sm text-gray-500">
                                            <div className="flex items-center space-x-2">
                                                <div className="flex flex-shrink-0 -space-x-1">
                                                    {item.hasOwnProperty(
                                                        'metadata'
                                                    ) &&
                                                    item.metadata.hasOwnProperty(
                                                        'property'
                                                    ) &&
                                                    item.metadata.property !==
                                                        '0'
                                                        ? properties.find(
                                                              (x) =>
                                                                  x._id ===
                                                                  item.metadata
                                                                      .property
                                                          )?.name
                                                        : null}
                                                </div>
                                            </div>
                                        </td>
                                        <td className="px-6 py-3 text-sm text-gray-500">
                                            <div className="flex items-center space-x-2">
                                                <div className="flex flex-shrink-0 -space-x-1">
                                                    {niceBytes(item.length)}
                                                </div>
                                            </div>
                                        </td>
                                        <td className="hidden md:table-cell px-6 py-3 whitespace-nowrap text-sm text-gray-500 text-right">
                                            {new Date(
                                                item.uploadDate
                                            ).toLocaleDateString()}
                                        </td>
                                        <td className="pr-6">
                                            <Menu
                                                as="div"
                                                className="relative flex justify-end items-center"
                                            >
                                                {({ open }) => (
                                                    <>
                                                        <Menu.Button className="w-8 h-8 bg-white inline-flex items-center justify-center text-gray-400 rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                                                            <span className="sr-only">
                                                                Open options
                                                            </span>
                                                            <DotsVerticalIcon
                                                                className="w-5 h-5"
                                                                aria-hidden="true"
                                                            />
                                                        </Menu.Button>
                                                        <Transition
                                                            show={open}
                                                            as={Fragment}
                                                            enter="transition ease-out duration-100"
                                                            enterFrom="transform opacity-0 scale-95"
                                                            enterTo="transform opacity-100 scale-100"
                                                            leave="transition ease-in duration-75"
                                                            leaveFrom="transform opacity-100 scale-100"
                                                            leaveTo="transform opacity-0 scale-95"
                                                        >
                                                            <Menu.Items
                                                                static
                                                                className="mx-3 origin-top-right absolute right-7 top-0 w-48 mt-1 rounded-md shadow-lg z-10 bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none"
                                                            >
                                                                <div className="py-1">
                                                                    {!isTrash && (
                                                                        <Menu.Item>
                                                                            {({
                                                                                active
                                                                            }) => (
                                                                                <span
                                                                                    onClick={(
                                                                                        e
                                                                                    ) => {
                                                                                        e.stopPropagation();
                                                                                        setShowEditFile(
                                                                                            item
                                                                                        );
                                                                                    }}
                                                                                    className={classNames(
                                                                                        active
                                                                                            ? 'bg-gray-100 text-gray-900'
                                                                                            : 'text-gray-700',
                                                                                        'group flex items-center px-4 py-2 text-sm'
                                                                                    )}
                                                                                >
                                                                                    <PencilAltIcon
                                                                                        className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                                                                        aria-hidden="true"
                                                                                    />
                                                                                    Bearbeiten
                                                                                </span>
                                                                            )}
                                                                        </Menu.Item>
                                                                    )}
                                                                    {isTrash && (
                                                                        <Menu.Item>
                                                                            {({
                                                                                active
                                                                            }) => (
                                                                                <span
                                                                                    onClick={async (
                                                                                        e
                                                                                    ) => {
                                                                                        e.stopPropagation();
                                                                                        await revertFile(
                                                                                            item._id
                                                                                        );
                                                                                        setFiles(
                                                                                            [
                                                                                                ...files
                                                                                            ].filter(
                                                                                                (
                                                                                                    f
                                                                                                ) =>
                                                                                                    f._id !==
                                                                                                    item._id
                                                                                            )
                                                                                        );
                                                                                    }}
                                                                                    className={classNames(
                                                                                        active
                                                                                            ? 'bg-gray-100 text-gray-900'
                                                                                            : 'text-gray-700',
                                                                                        'group flex items-center px-4 py-2 text-sm'
                                                                                    )}
                                                                                >
                                                                                    <ReplyIcon
                                                                                        className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                                                                        aria-hidden="true"
                                                                                    />
                                                                                    Zurückkehren
                                                                                </span>
                                                                            )}
                                                                        </Menu.Item>
                                                                    )}
                                                                </div>

                                                                <div className="py-1">
                                                                    <Menu.Item>
                                                                        {({
                                                                            active
                                                                        }) => (
                                                                            <span
                                                                                onClick={async (
                                                                                    e
                                                                                ) => {
                                                                                    if (
                                                                                        confirm(
                                                                                            'Sind Sie sicher, dass Sie diese Datei löschen wollen?'
                                                                                        )
                                                                                    ) {
                                                                                        e.stopPropagation();
                                                                                        const x =
                                                                                            await deleteFile(
                                                                                                item._id
                                                                                            );
                                                                                        if (
                                                                                            x
                                                                                        ) {
                                                                                            setFiles(
                                                                                                [
                                                                                                    ...files
                                                                                                ].filter(
                                                                                                    (
                                                                                                        f
                                                                                                    ) =>
                                                                                                        f._id !==
                                                                                                        item._id
                                                                                                )
                                                                                            );
                                                                                        } else {
                                                                                            alert(
                                                                                                `Error deleting file, ${item._id}`
                                                                                            );
                                                                                        }
                                                                                    }
                                                                                }}
                                                                                className={classNames(
                                                                                    active
                                                                                        ? 'bg-gray-100 text-gray-900'
                                                                                        : 'text-gray-700',
                                                                                    'group flex items-center px-4 py-2 text-sm'
                                                                                )}
                                                                            >
                                                                                <TrashIcon
                                                                                    className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                                                                    aria-hidden="true"
                                                                                />
                                                                                Löschen
                                                                            </span>
                                                                        )}
                                                                    </Menu.Item>
                                                                </div>
                                                            </Menu.Items>
                                                        </Transition>
                                                    </>
                                                )}
                                            </Menu>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <FilePanel
                _id={selectedFileId}
                setOpen={setSelectedFileId}
                deleteCallback={(id: string) => {
                    setFiles([...files].filter((f) => f._id !== id));
                    setSelectedFileId(null);
                }}
                properties={properties}
                refresh={(f: any) => {
                    const oldFiles = [...files].filter(
                        (f) => f._id !== selectedFileId
                    );
                    oldFiles.push(f);
                    setFiles(oldFiles);
                    setSelectedFileId(f._id);
                }}
            />
            <EditFolderModal
                open={showEditFolder}
                setOpen={setShowEditFolder}
                folder={folder}
                folders={folders}
                setFolders={setFolders}
            />
            <EditFileModal
                open={showEditFile}
                setOpen={setShowEditFile}
                folder={folder}
                files={files}
                setFiles={setFiles}
            />
            <ConfirmModal
                title="Overwrite file?"
                description="Looks like a file with the same name exists in this folder already, do you want to overwrite it or do you want to upload it anyway?"
                okText="Overwrite"
                onOk={() => {}}
                cancelText="Upload new"
                onCancel={() => {}}
                open={false}
                setOpen={() => {}}
            />
            <DuplicateModal
                visible={showReplaceFileModal}
                setVisible={setShowReplaceFileModal}
                onOk={() => {}}
                onCancel={() => {}}
            />
        </>
    );
}
