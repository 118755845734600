/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { addFolder, editFolder } from '../../types/folders';

export default function EditFolderModal({
    open,
    setOpen,
    folder,
    folders,
    setFolders
}) {
    const [name, setName] = useState('');
    const cancelButtonRef = useRef(null);

    useEffect(() => {
        if (!open) setName('');
        else setName(open.name);
    }, [open]);

    return (
        <Transition.Root show={!!open} as={Fragment}>
            <Dialog
                as="div"
                static
                className="fixed z-10 inset-0 overflow-y-auto"
                initialFocus={cancelButtonRef}
                open={!!open}
                onClose={setOpen}
            >
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}

                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                            <div className="sm:flex sm:items-start">
                                <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg leading-6 font-medium text-gray-900"
                                    >
                                        Rename folder
                                    </Dialog.Title>
                                    <div className="mt-4">
                                        <div>
                                            <div className="w-full mt-0 relative rounded-md shadow-sm">
                                                <input
                                                    type="text"
                                                    name="folder"
                                                    id="folder"
                                                    value={name}
                                                    onChange={(e) =>
                                                        setName(e.target.value)
                                                    }
                                                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                                    placeholder="Folder name"
                                                />
                                                {/*
                                                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                                                </div>
                                                */}
                                            </div>
                                            {/*
                                            <p className="mt-2 text-sm text-red-600" id="email-error">
                                                Your password must be less than 4 characters.
                                            </p>
                                            */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-5 sm:mt-4 ">
                                <button
                                    type="button"
                                    className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                                    onClick={async () => {
                                        if (name !== '') {
                                            const fld: any = await editFolder({
                                                ...open,
                                                name
                                            });
                                            setOpen(false);
                                            setName('');
                                            setFolders(
                                                [...folders].map((f) => {
                                                    if (f._id === fld._id) {
                                                        return fld;
                                                    }
                                                })
                                            );
                                        }
                                    }}
                                >
                                    Create
                                </button>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
