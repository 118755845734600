import React, { useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { FolderAddIcon } from '@heroicons/react/solid';
import { addProperty, getProperties } from '../../types/properties';
import classNames from '../../utilities/utilities';
import TextModal from '../../components/TextModal/TextModal';

function PropertiesPage(props) {
    const [properties, setProperties] = useState([]);
    const [showPropertyModal, setShowPropertyModal] = useState(false);

    const { authState, oktaAuth } = useOktaAuth();
    const login = () =>
        oktaAuth.signInWithRedirect({ originalUri: '/profile' });

    useEffect(() => {
        (async function fetchData() {
            // eslint-disable-next-line no-shadow
            const p = await getProperties();
            setProperties(p);
        })();
    }, []);

    return (
        <>
            <div className="pb-5 sm:flex sm:items-center">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Objekte
                </h3>
                <div className="mt-3 sm:mt-0 ml-auto">
                    <button
                        type="button"
                        onClick={() => setShowPropertyModal(true)}
                        className="ml-2 inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        <FolderAddIcon
                            className="-ml-0.5 mr-2 h-4 w-4"
                            aria-hidden="true"
                        />
                        Neue Objekt
                    </button>
                </div>
            </div>
            <div className="h-full">
                <div
                    className={classNames(
                        'transition duration-200 ease-in-out rounded-lg border-2 border-gray-200 align-middle inline-block min-w-full border-gray-200'
                    )}
                >
                    <table className="min-w-full">
                        <thead className="sticky top-0">
                            <tr className="border-t border-gray-200">
                                <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    <span className="lg:pl-2">Name</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-100">
                            {properties.map((p) => (
                                <tr key={p._id}>
                                    <td className="px-6 py-3 max-w-0 w-full whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer">
                                        {p.name}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <TextModal
                open={showPropertyModal}
                setOpen={setShowPropertyModal}
                text="Neue Objekt erstellen"
                onOk={(t) =>
                    new Promise(async (resolve, reject) => {
                        const x = await addProperty(t);
                        setProperties([...properties, x]);
                        setShowPropertyModal(false);
                        resolve();
                    })
                }
            />
        </>
    );
}

export default PropertiesPage;
