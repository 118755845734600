import React, { useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useParams } from 'react-router-dom';
import {
    DocumentAddIcon,
    FolderAddIcon,
    SearchIcon,
    TrashIcon
} from '@heroicons/react/solid';
import FilesTable from './FilesTable/FilesTable';
import { emptyTrash, folder, getItems } from '../../types/folders';
import FolderModal from '../../components/FolderModal/FolderModal';
import { uploadFiles } from '../../types/files';
import { getFileName } from '../../utilities/utilities';
import { useProperties } from '../../hooks/useProperties';
import FileBrowserHeader from './FileBrowserHeader';

interface Props {}

function FileBrowser(props: Props) {
    // Main App state
    const [files, setFiles] = useState([]);
    const [folders, setFolders] = useState([]);

    const [folderName, setFolderName] = useState('');

    const [showFolderModal, setShowFolderModal] = useState(false);

    const [search, setSearch] = useState('');
    const [property, setProperty] = useState('0');

    const { authState, oktaAuth } = useOktaAuth();

    const login = () =>
        oktaAuth.signInWithRedirect({ originalUri: '/profile' });

    // Folder to fetch
    const { folderId }: any = useParams();

    // Get and set properties on first component load
    const { properties } = useProperties();

    // Get and set files, folders and folderName everytime the target folder changes
    useEffect(() => {
        (async function () {
            const { folders, files, folderName } = await getItems(folderId);
            console.log({ folders, files, folderName });
            setFiles(files || []);
            setFolders(folders || []);
            setFolderName(folderName);
        })();
    }, [folderId]);

    return (
        <>
            <FileBrowserHeader
                setShowFolderModal={setShowFolderModal}
                folderName={folderName}
                folderId={folderId}
                files={files}
                setFiles={setFiles}
                setFolders={setFolders}
                search={search}
                setSearch={setSearch}
            />
            <div className="pb-5">
                <div>
                    <label className="text-xs">Objekt</label>
                    <select
                        id="location"
                        name="location"
                        onChange={(e) => {
                            setProperty(e.target.value);
                        }}
                        className="mt-1 block pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                    >
                        <option value="0">Alle</option>
                        {properties.map((p: any) => (
                            <option value={p._id}>{p.name}</option>
                        ))}
                    </select>
                </div>
            </div>
            <div className="h-full">
                <FilesTable
                    folders={folders.filter((f: folder) => {
                        if (search === '') return true;
                        return f.name
                            .toLowerCase()
                            .includes(search.toLowerCase());
                    })}
                    properties={properties}
                    folder={folderId}
                    setFolders={setFolders}
                    files={files
                        .filter((f: file) => {
                            if (search === '') return true;
                            return f.filename
                                .toLowerCase()
                                .includes(search.toLowerCase());
                        })
                        .filter((f: file) => {
                            if (property === '0') return true;
                            return f?.metadata?.property === property;
                        })}
                    setFiles={setFiles}
                />
            </div>
            <FolderModal
                open={showFolderModal}
                setOpen={setShowFolderModal}
                folder={folderId}
                folders={folders}
                setFolders={setFolders}
            />
        </>
    );
}

export default FileBrowser;
